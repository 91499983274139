<template>
    <el-dialog :visible.sync="visible" :title="title" @close="closeDialog">
        <el-form ref="maForm" :model="model" size="small" label-width="90px" :rules="rules" :disabled="isDisabled">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="物资名称" prop="cName">
                        <el-input v-model="model.cName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="编码" prop="code">
                        <el-input v-model="model.code"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="自定义编码" prop="cCode">
                        <el-input v-model="model.cCode"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="型号" prop="model">
                        <el-input v-model="model.model"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="回收类别" prop="recycleTId">
                        <div @click="selectRecycleType">
                            <el-input placeholder="请选择" v-model="model.recycleTNa"></el-input>
                        </div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="状态" prop="status">
                        <el-radio-group v-model="model.status">
                            <el-radio-button label="1">启用</el-radio-button>
                            <el-radio-button label="0">禁用</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="参考价格（元）" prop="price">
                        <el-input v-model="model.price"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="备注" prop="remark">
                        <el-input v-model="model.remark"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="图片">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadImgUrl"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            :headers="uploadHeader"
                        >
                            <img v-if="model.imgUrl" :src="model.imgUrl" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <div class="handle_buttons" v-if="!isDisabled">
            <el-button type="primary" size="small" @click="saveM">确定</el-button>
            <el-button size="small" @click="visible = false">取消</el-button>
        </div>

        <recycle-types-tree :modal="false" ref="recycle" @addInstitutions="addInstitutions" v-if="!isDisabled"></recycle-types-tree>
    </el-dialog>
</template>

<script>
    import recycleTypesTree from "../../common/dialog-tree/recycleTypes_tree.vue";
    import { cloneDeep } from "lodash";
    export default {
        methods: {
            closeDialog() {
                this.model = {
                    cName: "",
                    code: "",
                    cCode: "",
                    recycleTId: "",
                    recycleTNa: "",
                    model: "",
                    price: null,
                    status: "1",
                    remark: "",
                    imgUrl: "",
                    materialId: ""
                };

                this.$refs.maForm.resetFields();
            },
            setUploadHeader() {
                let header = {
                    token: this.$util.getStor("token")
                };
                this.uploadHeader = header;
            },
            saveM() {
                let url = "/material/add";
                if (this.model.materialId) {
                    url = "/material/update";
                }
                this.$refs.maForm.validate(valide => {
                    if (valide) {
                        this.$http.post(url, this.model).then(res => {
                            this.$message.success({
                                message: res.msg
                            });
                            this.visible = false;
                            this.$emit("update");
                        });
                    }
                });
            },
            init(options, isView) {
                if (options && !isView) {
                    this.$nextTick(() => {
                        this.model = cloneDeep(options);
                    });
                    this.title = "编辑物资";
                    this.isDisabled = false;
                } else if (options && isView) {
                    this.$nextTick(() => {
                        this.model = cloneDeep(options);
                    });
                    this.title = "查看物资";
                    this.isDisabled = true;
                } else {
                    this.title = "新增物资";
                    this.isDisabled = false;
                }
                this.visible = true;
            },
            selectRecycleType() {
                if (!this.isDisabled) {
                    this.$refs.recycle.init();
                }
            },
            addInstitutions(data) {
                this.model.recycleTNa = data.cName;
                this.model.recycleTId = data.recycleTId;
            },
            beforeAvatarUpload(file) {
                console.log(file.type);
                const isJPG = file.type === "image/jpeg" || file.type === "image/png";

                if (!isJPG) {
                    this.$message.error("图片只能是JPG或PNG格式");
                }
                return isJPG;
            },
            handleAvatarSuccess({ detail }) {
                this.model.imgUrl = detail;
            }
        },
        data() {
            return {
                visible: false,
                isDisabled: false,
                title: "",

                model: {
                    cName: "",
                    code: "",
                    cCode: "",
                    recycleTId: "",
                    recycleTNa: "",
                    model: "",
                    price: null,
                    status: "1",
                    remark: "",
                    imgUrl: "",
                    materialId: ""
                },
                rules: {
                    cName: [{ required: true, message: "物资名称不为空", trigger: "blur" }],
                    recycleTId: [{ required: true, message: "回收类别不为空", trigger: "change" }],
                    status: [{ required: true, message: "状态不为空", trigger: "change" }]
                },
                uploadImgUrl: "/iwastes-admin/sys/user/uploadLogo",
                uploadHeader: {}
            };
        },
        created() {
            this.setUploadHeader();
        },
        components: {
            recycleTypesTree
        }
    };
</script>
<style scoped>
    .handle_buttons {
        text-align: center;
    }
</style>
