<template>
    <div>
        <jy-query labelWidth="120" class="query_area" size="small">
            <jy-query-item label="物资编码" prop="code">
                <el-input v-model="formInline.code"></el-input>
            </jy-query-item>
            <jy-query-item label="物资自定义编码" prop="cCode">
                <el-input v-model="formInline.cCode"></el-input>
            </jy-query-item>
            <jy-query-item label="物资名称" prop="cName">
                <el-input v-model="formInline.cName"></el-input>
            </jy-query-item>
            <jy-query-item label="型号" prop="model">
                <el-input v-model="formInline.model"></el-input>
            </jy-query-item>
            <jy-query-item label="状态" prop="status">
                <el-select v-model="formInline.status">
                    <el-option v-for="available in statusList" :label="available.name" :key="available.value" :value="available.value"></el-option>
                </el-select>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="search" v-if="btnexist('wastesBasicInfoMaterialsList')">查询</el-button>
                <el-button type="primary" @click="reset" v-if="btnexist('wastesBasicInfoMaterialsReset')">重置</el-button>
                <el-row>
                    <el-button type="primary" v-on:click="add" v-if="btnexist('wastesBasicInfoMaterialsInsert')">新增</el-button>
                    <el-button type="primary" v-on:click="batchEdit" v-if="btnexist('wastesBasicInfoMaterialsBatchUpdateType')">批量修改类别</el-button>
                    <export-excel
                        v-if="btnexist('wastesBasicInfoMaterialsExport') && false"
                        size="small"
                        url="/goodsStock/exportStock"
                        :params="formInline"
                        fileName="商品库存"
                        fileType=".xls"
                    ></export-excel>
                </el-row>
            </template>
        </jy-query>

        <el-row type="flex" justify="space-between">
            <el-col :span="4" style="margin: 10px; padding: 5px">
                <p class="treeHeader">回收类别</p>
                <el-tree
                    ref="typeTree"
                    :props="defaltProps"
                    :data="typeTreeData"
                    style="max-height: 650px; overflow-y: hidden"
                    node-key="recycleTId"
                    :highlight-current="true"
                    @node-click="nodeClick"
                    accordion
                ></el-tree>
            </el-col>
            <el-col :span="20">
                <div class="jy_table" v-loading="loading">
                    <c-table
                        ref="ctable"
                        selectMode="check"
                        :columns="columns"
                        :data="tableData"
                        :total="total"
                        operateWidth="200"
                        :btnList="operateList"
                        @update="init"
                        @cell-click="cellBtnClick"
                    ></c-table>
                </div>
            </el-col>
        </el-row>

        <add-update ref="addUpdate" @update="init"></add-update>
        <update-type ref="type" @update="init"></update-type>
    </div>
</template>

<script>
    import ExportExcel from "@/components/pages/admin/common/export";
    import CTable from "@/components/pages/admin/common/ctable.vue";
    import AddUpdate from "@/components/pages/admin/basic/materials/addUpdate.vue";
    import UpdateType from "@/components/pages/admin/basic/materials/UpdateType.vue";

    import { btnMixins } from "@/common/js/button.mixin";
    export default {
        mixins: [btnMixins],
        methods: {
            setOperateList() {
                let l = [];
                if (this.btnexist("wastesBasicInfoMaterialsView")) {
                    l.push({
                        index: "view",
                        title: "查看"
                    });
                }
                if (this.btnexist("wastesBasicInfoMaterialsUpdate")) {
                    l.push({
                        index: "edit",
                        title: "编辑"
                    });
                }
                if (this.btnexist("wastesBasicInfoMaterialsDelete")) {
                    l.push({
                        index: "delete",
                        title: "删除"
                    });
                }
                this.operateList = l;
            },
            search() {
                this.$refs.ctable.reset();
                this.init();
            },
            reset() {
                this.formInline = {
                    code: "",
                    cCode: "",
                    cName: "",
                    model: "",
                    status: ""
                };

                this.$refs.typeTree.setCurrentKey();

                this.init();
            },
            async init() {
                this.loading = true;
                const page = this.$refs.ctable.getPager();
                const {
                    detail: { total, list }
                } = await this.$http.post("/material/pageQuery", {
                    ...this.formInline,
                    ...page
                });
                this.tableData = list.map(v => {
                    return {
                        ...v,
                        recycleTNa: ""
                    };
                });
                this.total = total;

                const { detail: recycles } = await this.$http.post("/recycleType/batchQueryByRecycleIds", this.tableData.map(v => v.recycleTId).join(","));

                this.tableData.forEach(row => {
                    const recycle = recycles.find(v => v.recycleTId === row.recycleTId);
                    if (recycle) {
                        row.recycleTNa = recycle.cName;
                    }
                });
                this.loading = false;
            },
            add() {
                this.$refs.addUpdate.init();
            },
            batchEdit() {
                const selection = this.$refs.ctable.selection;
                if (selection.length <= 0) {
                    this.$message.warning({
                        message: "请选择要修改的物资",
                        duration: 1000
                    });
                    return;
                }

                this.$refs.type.init(selection.map(v => v.materialId).join(","));
            },
            cellBtnClick(index, btnIndex, item) {
                if (btnIndex === "edit") {
                    this.$refs.addUpdate.init(item);
                } else if (btnIndex === "delete") {
                    this.$confirm("是否删除此物资", "删除", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(() => {
                        this.$http.post("/material/delete", item.materialId).then(res => {
                            if (res.code === "0") {
                                this.$message.success({
                                    message: "删除成功"
                                });
                                this.init();
                            }
                        });
                    });
                } else if (btnIndex === 'view') {
                    this.$refs.addUpdate.init(item, true);
                }
            },
            nodeClick(data) {
                this.formInline.recycleTId = data.recycleTId;
                this.init();
            },
            getTypeTreeData() {
                this.$http.post("/recycleType/listTree").then(res => {
                    this.typeTreeData = [];
                    this.handleRecycleTypes(res.detail);
                });
            },
            handleRecycleTypes(menus, pmenu) {
                menus.forEach(v => {
                    if (v.isDel !== "1") {
                        const item = {
                            cName: v.cName,
                            recycleTId: v.recycleTId,
                            pRecycleTId: v.pRecycleTId
                        };
                        if (pmenu) {
                            if (!pmenu.children) {
                                pmenu.children = [];
                            }
                            pmenu.children.push(item);
                        } else {
                            this.typeTreeData.push(item);
                        }
                        if (v.children) {
                            this.handleRecycleTypes(v.children, item);
                        }
                    }
                });
            }
        },
        data() {
            return {
                loading: false,
                formInline: {
                    code: "",
                    cCode: "",
                    model: "",
                    cName: "",
                    status: ""
                },

                statusList: [
                    {
                        name: "全部",
                        value: ""
                    },
                    {
                        name: "启用",
                        value: "1"
                    },
                    {
                        name: "禁用",
                        value: "0"
                    }
                ],

                tableData: [],
                total: 0,
                columns: [
                    {
                        title: "编码",
                        prop: "code",
                        minWidth: 180
                    },
                    {
                        title: "自定义编码",
                        prop: "cCode",
                        minWidth: 180
                    },
                    {
                        title: "物资名称",
                        prop: "cName",
                        minWidth: 180
                    },
                    {
                        title: "型号",
                        prop: "model"
                    },
                    {
                        title: "回收类别",
                        prop: "recycleTNa"
                    },
                    {
                        title: "参考价格(元)",
                        prop: "price"
                    },
                    {
                        title: "状态",
                        prop: "status",
                        formatter: (rowIndex, key, item) => {
                            return item[key] === "1" ? "启用" : "禁用";
                        }
                    }
                ],
                operateList: [],

                typeTreeData: [],
                defaltProps: {
                    label: "cName"
                },
                btnMenuId: "wastesBasicInfoMaterials"
            };
        },
        components: {
            ExportExcel,
            CTable,
            AddUpdate,
            UpdateType
        },
        created() {
            this.getTypeTreeData();
        },
        mounted() {
            this.setOperateList();
            this.$nextTick(() => {
                this.init();
            });
        }
    };
</script>
<style scoped>
    .treeHeader {
        height: 50px;
        background-color: rgb(201, 201, 201);
        line-height: 50px;
        text-align: center;
        font-size: 20px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
</style>
