<template>
    <el-dialog :visible.sync="visible" title="批量修改回收类别" width="400px" @close="closeDialog">
        <span>回收类别</span>
        <el-input placeholder="请选择" v-model="recycleTNa" @focus="selectRecycleType"></el-input>

        <div class="handle_buttons">
            <el-button size="small" type="primary" @click="save">确定</el-button>
            <el-button size="small" @click="visible = false">取消</el-button>
        </div>

        <recycle-types-tree :modal="false" ref="recycle" @addInstitutions="addInstitutions"></recycle-types-tree>
    </el-dialog>
</template>

<script>
    import recycleTypesTree from "../../common/dialog-tree/recycleTypes_tree.vue";
    export default {
        methods: {
            closeDialog() {
                this.recycleTNa = "";
                this.recycleTId = "";
            },
            selectRecycleType() {
                this.$refs.recycle.init();
            },
            addInstitutions(data) {
                this.recycleTNa = data.cName;
                this.recycleTId = data.recycleTId;
            },
            init(option) {
                this.materialIds = option;
                this.visible = true;
            },
            save() {
                if (this.recycleTId) {
                    let url = "/material/batchUpdateRecycleTypeByMaterialIds";
                    this.$http
                        .post(url, {
                            materialIds: this.materialIds,
                            recycleTId: this.recycleTId
                        })
                        .then(res => {
                            this.$message.success({
                                message: res.msg
                            });
                            this.visible = false;
                            this.$emit("update");
                        });
                }
            }
        },
        data() {
            return {
                visible: false,

                materialIds: "",
                recycleTId: "",
                recycleTNa: ""
            };
        },
        components: {
            recycleTypesTree
        }
    };
</script>
<style scoped>
    .el-input {
        width: 180px;
        margin-left: 10px;
    }
    .handle_buttons {
        text-align: center;
        margin-top: 10px;
    }
</style>
